import GatsbyImage, { FluidObject } from 'gatsby-image'
import { BoundedBoxTw } from './BoundedBox'
import clsx from 'clsx'
import { HTMLContent } from './HTMLContent/HTMLContent'

interface Props {
  name?: string
  imageFluid?: FluidObject
  infoHTML?: string
}

export const DoctorInformation = ({
  name,
  imageFluid,
  infoHTML,
}: Props): JSX.Element => {
  return (
    <BoundedBoxTw
      as="section"
      className={clsx('py-[3.75rem] sm:py-20')}
      maxWidthClassName="max-w-md"
      innerClassName={clsx(
        'grid',
        'sm:grid-cols-2 sm:grid-rows-[auto,1fr] sm:gap-x-8 sm:items-start',
        'md:grid-cols-[6fr,4fr] md:gap-x-[3.75rem]'
      )}
    >
      {imageFluid && (
        <div
          className={clsx(
            'mb-7 sm:row-span-2 sm:mb-0',
            'aspect-w-4 aspect-h-3',
            'md:aspect-w-16 md:aspect-h-10'
          )}
        >
          <div>
            <GatsbyImage
              fluid={imageFluid}
              alt={name}
              imgStyle={{ objectFit: 'cover' }}
              className="w-full h-full"
            />
          </div>
        </div>
      )}
      {name && (
        <div
          className={clsx(
            'mb-10',
            'sm:col-start-2 sm:row-start-1 sm:mb-7 sm:mt-1',
            'md:mb-8'
          )}
        >
          <h2 className="serif-24-1_5 md:serif-30-1_5 lg:serif-36-1_5">
            Dr. {name}
          </h2>
        </div>
      )}
      {infoHTML && (
        <HTMLContent
          html={infoHTML}
          className="sm:col-start-2 sm:row-start-2"
          variant="doctorHtmlContent"
        />
      )}
    </BoundedBoxTw>
  )
}

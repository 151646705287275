import { graphql, PageProps } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { DoctorInformation } from '../components/DoctorInformation'
import { SEO } from '../components/SEO'
import { DoctorTemplateQuery } from '../types.gql'
import PageBodySmallHero from '../slices/PageBodySmallHero'
import PageBodyCallToAction from '../slices/PageBodyCallToAction'
import PageBodyBreadcrumbs from '../slices/PageBodyBreadcrumbs'
import { TI_LEAF_FLUID_OBJ } from '../lib/constants'

const DoctorTemplate = ({
  data: queryData,
}: PageProps<DoctorTemplateQuery>) => {
  const data = queryData.prismicDoctor?.data!

  return (
    <>
      <SEO pageTitle={data?.name?.text ?? 'Doctor'} />
      <PageBodySmallHero
        heading="Our Doctors"
        imageFluid={TI_LEAF_FLUID_OBJ}
        textHTML={undefined}
        bgClassName="bg-white"
      />

      <PageBodyBreadcrumbs
        maxWidthClassName="max-w-md"
        paths={[
          { href: 'about-us', title: 'About Us' },
          { href: 'about-us/our-doctors', title: 'Our Doctors' },
          {
            href: `about-us/our-doctors/${queryData.prismicDoctor?.uid}`,
            title: data.name?.text!,
          },
        ]}
        shouldNegativeMargin
      />

      <DoctorInformation
        name={data.name?.text}
        imageFluid={data.image?.fluid as FluidObject}
        infoHTML={data.additional_information?.html}
      />

      <PageBodyCallToAction smallHeading="Bio" textHTML={data.bio?.html} />
    </>
  )
}

export default DoctorTemplate

export const query = graphql`
  query DoctorTemplate($uid: String!) {
    prismicDoctor(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        additional_information {
          html
        }
        bio {
          html
        }
        image {
          fluid(maxWidth: 800) {
            ...GatsbyPrismicImageFluid
          }
        }
        name {
          text
        }
      }
    }
  }
`
